<template>
    <div class="fix-full" v-if="!!roomTableData">
        <div class="absolute-full">
            <div class="mask" @click="closeWindow"></div>
            <div class="window-container">
                <div class="avatar-box">
                   <img :src="roomIcon"
                         class="avatar-img">
                </div>
                <h2 class="table-title">
                    <span><label>房号</label> <strong class="staff-no">{{roomDetail.room_name}}</strong></span>
                    <span><label>总消费</label> <strong class="total-price">￥{{totalPrice
                                                                         }}</strong></span>
                </h2>
                <div class="scroll-container">
                    <div class="technician-detail">
                        <technician-detail-table :tableData="roomTableData"></technician-detail-table>
                    </div>
                </div>
                <div class="btn-box"
                     @click="closeWindow">
                    <button class="close-btn">我知道了</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name:"room-detail",
		props:{
			roomDetail:{
				type:Object,
				default:() => {}
			}
		},
		data(){
			return {
				roomIcon:require('@/assets/images/room-detail.png'),
				roomTableData:{
					headList:[
						{name:'工号',filed:'staff_no',width:'.1rem'},
						{name:'服务项目',filed:'service_project',width:'2rem'},
						{name:'上钟方式',filed:'work_type',width:'1rem'},
						{name:'余时(分)',filed:'remain_time',width:'.1rem'},
						{name:'金额',filed:'price_unit',width:'1.2rem'},
					],
					dataList:[],
				},
				totalPrice:null,
			}
		},
		computed:{
			totalPrice(){
				let {roomTableData} = this
				let totalPrice = 0
				for(let item of roomTableData.dataList){
					totalPrice += item.price
				}
				return totalPrice
            }
		},
        watch:{
			roomTableData:{
				handler(newVal){
					let totalPrice = 0
					for(let item of newVal.dataList){
						totalPrice += parseFloat(item.price)
					}
					this.totalPrice = totalPrice
				},
				deep:true,
				immediate:true,
            }
        },
		components:{
			'technician-detail-table':() => import('../technician/technician-state/technician-detail-table')
		},
		methods:{
			getTableData(){
				let {roomDetail,roomTableData} = this
                for(let item of roomDetail.service_list){
					if(item.price=='.00'){
						item.price = '0.00'
					}
					item.price_unit = "￥" + item.price
				}
				roomTableData.dataList = roomDetail.service_list
			},
			closeWindow(){
				this.$emit('closeWindow')
			}
		},
		created(){
			this.getTableData()
        }
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .fix-full{
        @extend %fix-full;
        z-index:2;
    }

    .absolute-full{
        @extend %full;
        @extend %flex-center-row;
    }

    .mask{
        @extend %mask;
    }

    .window-container{
        position:absolute;
        left:70px;
        right:70px;
        z-index:1;
        background:#FFFFFF;
        border-radius:10px;
        padding:0 30px;
    }

    .avatar-box{
        height:105px;
        position:relative;
    }

    .avatar-img{
        width:170px;
        height:170px;
        border-radius:50%;
        position:absolute;
        top:-70px;
        left:50%;
        margin:0 0 0 -85px;
    }

    .scroll-container{
        max-height:52vh;
        @extend %scroll-container;
    }

    .table-title{
        display:flex;
        justify-content:space-between;
        margin:10px 0 0;
        line-height:65px;
        height:65px;
        font-size:24px;
        color:#666666;
    }
    .staff-no{
        font-size:32px;
        color:#000000;
    }
    .total-price{
        font-size:32px;
        color:#ec2108;
    }
    .btn-box{
        margin:40px 0;

        .close-btn{
            display:inline-block;
            background:$high-light-color;
            color:#FFFFFF;
            width:100%;
            height:75px;
            line-height:75px;
            font-size:28px;
            text-align:center;
            border-radius:10px;
            font-weight:bold;
        }
    }
</style>
